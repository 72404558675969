<template>
  <v-container fluid v-if="selectedTarifAndOptions">
    <v-expansion-panels>
      <v-expansion-panel class="rounded-lg">
        <!-- Header -->
        <v-expansion-panel-header disable-icon-rotate class="pa-10">
          <v-row justify="space-between" align="center">
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <h4 class="mb-2 text-h4">Basistarif Friends</h4>
              <p>(Hausstrom)</p></v-col
            >
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              <div class="text-body-1">
                <p class="my-1">
                  <span class="font-weight-bold">Grundpreis:</span>
                  {{
                    selectedTarifAndOptions.tarif.base_price
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                  {{ selectedTarifAndOptions.tarif.base_price_unit }}
                </p>
                <p class="my-1">
                  <span class="font-weight-bold">Arbeitspreis:</span>
                  {{
                    selectedTarifAndOptions.tarif.working_price
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                  {{ selectedTarifAndOptions.tarif.working_price_unit }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              lg="3"
              xl="3"
              :class="`mt-4 mr-2 ${
                $vuetify.breakpoint.smAndUp ? 'text-right' : ''
              }`">
              <p class="mr-2 text-body-1 font-weight-bold">
                Vertrags<wbr />informationen
              </p>
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-icon color="black" medium> mdi-chevron-down </v-icon>
          </template>
        </v-expansion-panel-header>
        <!-- Content -->
        <v-expansion-panel-content id="TarifHeader" class="pa-0 text-body-2">
          <v-sheet class="pa-10 pb-16" color="background_primary">
            <h2
              :class="`text-h5 mb-10 ${
                $vuetify.breakpoint.smAndUp ? 'px-9' : 'px-0'
              } mt-2`">
              Vertragsdetails
            </h2>
            <v-row :class="`${$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'}`">
              <v-col cols="12" sm="6">
                <!-- Consumption row -->
                <v-row>
                  <v-col cols="7" class="py-0">
                    <span class="font-weight-bold"
                      >Angegebener Verbrauch pro Jahr:</span
                    >
                  </v-col>
                  <v-col class="text-right py-0">
                    <span
                      ><nobr
                        >{{
                          selectedTarifAndOptions.information.consumption
                        }}
                        kWh</nobr
                      ></span
                    >
                  </v-col>
                </v-row>
                <!-- Price row -->
                <v-row class="mt-10">
                  <v-col cols="12" md="6" class="py-0">
                    <span class="font-weight-bold">Kalkulierter Abschlag</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    :class="`text-h4 ${
                      $vuetify.breakpoint.mdAndUp ? 'text-right' : 'mt-2'
                    } py-0`">
                    <span>
                      <nobr>
                        {{
                          selectedTarifAndOptions.tarif.price_per_month
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                        {{ selectedTarifAndOptions.tarif.price_per_month_unit }}
                      </nobr>
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Cancelation data -->
              <v-col offset-sm="1" cols="12" sm="5">
                <!-- Cancelation row -->
                <v-row :class="`${$vuetify.breakpoint.smAndUp ? '' : 'mt-3'}`">
                  <v-col cols="9" class="py-0">
                    <span class="font-weight-bold">Vertragsdauer:</span>
                  </v-col>
                  <v-col cols="3" class="text-right py-0">
                    <span>31.12.2024</span>
                  </v-col>
                </v-row>
                <!-- Cancelation time row -->
                <v-row>
                  <v-col cols="9" class="py-0">
                    <span class="font-weight-bold">Kündigungsfrist</span>
                  </v-col>
                  <v-col cols="3" class="text-right py-0">
                    <span>4 Wochen </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet
            :class="`rounded-b-lg ${
              $vuetify.breakpoint.smAndUp ? 'pa-10' : 'pa-4'
            } pb-16`">
            <!-- Sum -->
            <v-row
              :class="`text-body-2 mt-5 ${
                $vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
              }`">
              <v-col
                cols="9"
                :class="`${$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`">
                <h3 class="text-h4">Summe für 12 Monate</h3>
              </v-col>
              <v-col
                cols="3"
                :class="`text-right ${
                  $vuetify.breakpoint.smAndUp ? '' : 'pl-0'
                }`">
                <span class="text-h6"
                  ><nobr
                    >{{
                      pricePerYear.toFixed(2).toString().replace(".", ",")
                    }}
                    €</nobr
                  ></span
                >
              </v-col>
            </v-row>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["props", "edit", "index"],
  data() {
    return {
      mehrPrices: null,
    };
  },
  computed: {
    ...mapState({
      selectedTarifAndOptions: state => {
        return state.selectedTarifAndOptions;
      },
      language: state => {
        return state.language ?? "de";
      },
    }),
    pricePerYear() {
      return this.selectedTarifAndOptions.tarif.price_per_month * 12;
    },
  },
};
</script>
<style>
#TarifHeader .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>

<template>
  <v-form ref="formPayments">
    <h1 class="text-h5 pb-10">Zahlungsmodalitäten</h1>
    <v-row>
      <v-col class="pb-0">
        <v-checkbox
          v-model="isDifferentAddress"
          label="Abweichende Rechnungsanschrift"></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="isDifferentAddress == 1" class="mt-0 pb-5">
      <v-col cols="6">
        <v-select
          v-model="form.differentPaymentAddress.salutation.value"
          :items="[`Herr`, `Frau`]"
          label="Anrede*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="form.differentPaymentAddress.title.value"
          :items="[`Prof.`, `Dr.`, `Ing.`, `Kfm.`]"
          label="Titel"></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.differentPaymentAddress.firstName.value"
          label="Vorname*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.differentPaymentAddress.lastName.value"
          label="Nachname*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.differentPaymentAddress.street.value"
          label="Straße*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.differentPaymentAddress.number.value"
          label="Hausnummer*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.differentPaymentAddress.postalcode.value"
          label="Postleitzahl*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.differentPaymentAddress.region.value"
          label="Ort*"
          :rules="[v => !!v || 'Bitte ausfüllen!']"
          required></v-text-field>
      </v-col>
    </v-row>
    <h3 class="pt-5">ZAHLUNGSWEISE</h3>
    <p>
      Die Zahlung erfolgt bargeldlos. Der Kunde kann seine Abschläge oder
      Rechnungen durch Banküberweisung oder durch SEPA-Lastschrift begleichen.
    </p>

    <v-radio-group v-model="paymentType" column mandatory>
      <v-radio
        label="Per Überweisung (mit Terminstellung bis zum 5. eines Monats)"
        value="1">
      </v-radio>
      <v-radio
        label="Per Einzugsermächtigung und SEPA-Lastschriftmandat"
        value="2">
      </v-radio>
    </v-radio-group>
    <div v-if="paymentType == 2">
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.paymentType.accountholder_first_name.value"
            label="Vorname des Kontoinhabers*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.paymentType.accountholder_last_name.value"
            label="Nachname des Kontoinhabers*"
            :rules="[v => !!v || 'Bitte ausfüllen!']"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="form.paymentType.iban.value"
            label="IBAN*"
            :rules="[
              v => !!v || 'Bitte ausfüllen!',
              v => checkIBAN(v) || 'Die angegebene IBAN ist nicht gültig!',
            ]"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-checkbox
        v-model="paymentAgreement"
        :label="paymentAgreementText"
        :rules="[v => !!v || 'Bitte bestätigen um fortzufahren!']"
        required></v-checkbox>
    </div>
    <p style="font-size: 8pt; color: grey">*Pflichtangaben</p>
  </v-form>
</template>

<script>
import Iban from "iban";
export default {
  data() {
    return {
      paymentType: 1,
      isDifferentAddress: 0,
      paymentAgreement: 0,
      paymentAgreementText:
        "Hiermit ermächtige ich den Lieferanten fällige Zahlungen unter der Gläubiger-Identifikationsnummer DE73ZZZ00002047586 vom oben genannten Konto einzuziehen. Ich werde für eine ausreichende Deckung des Kontos sorgen.*",
      form: {
        text: "Zahlungsmodalitäten",
        paymentType: {
          text: "Zahlungsweise",
          type: { text: "Ausgewählt", value: "" },
          accountholder_first_name: {
            text: "Vorname des Kontoinhabers",
            value: "",
          },
          accountholder_last_name: {
            text: "Nachname des Kontoinhabers",
            value: "",
          },
          iban: { text: "IBAN", value: "" },
          // paymentAgreement: {
          //   text: "Zahlungsvereinbarung bestätigt?",
          //   value: "",
          // },
        },
        differentPaymentAddress: {
          text: "Abweichende Rechnungsanschrift",
          isDifferentAddress: {
            text: "Auswahl",
            value: "",
          },
          salutation: { text: "Anrede", value: "" },
          title: { text: "Titel", value: "" },
          firstName: { text: "Vorname", value: "" },
          lastName: { text: "Nachname", value: "" },
          street: { text: "Straße", value: "" },
          number: { text: "Hausnummer", value: "" },
          postalcode: { text: "Postleitzahl", value: "" },
          region: { text: "Ort", value: "" },
        },
      },
    };
  },
  methods: {
    validate() {
      return this.$refs.formPayments.validate();
    },
    reset() {
      this.$refs.formPayments.reset();
    },
    resetValidation() {
      this.$refs.formPayments.resetValidation();
    },
    parseData() {
      // if (this.paymentAgreement == 1)
      //   this.form.paymentType.paymentAgreement.value = "Ja";
      this.form.differentPaymentAddress.isDifferentAddress.value =
        this.isDifferentAddress == 1
          ? "Ja"
          : "Rechnungsanschrift und Lieferadresse stimmen überein.";
      this.form.paymentType.type.value =
        this.paymentType == 1
          ? "Per Überweisung"
          : "Per Einzugsermächtigung und SEPA-Lastschriftmandat";
    },
    saveData(id) {
      this.parseData();
      let data = {
        id: id,
        section: "formPayment",
        data: this.form,
        enabled: true,
        differentAddress: this.isDifferentAddress,
      };
      this.$store.commit("setCheckoutData", data);
    },
    checkIBAN(iban) {
      return Iban.isValid(iban);
    },
  },
  mounted() {
    this.resetValidation();
  },
};
</script>

<style></style>
